<template>
  <div class="about">
    <div class="content1">
      <div class="content1-box">
        <h3>诚邀合伙人</h3>
        <p>联系邮箱：zevie@acicada.com.cn</p>
        <p>联系地址：深圳市福田区福田街道福山社区福华三路与金田路交界处<br>&emsp;&emsp;&emsp;&emsp;&emsp;东南侧卓越世纪中心、皇岗商务中心1号楼3403A</p>
      </div>
    </div>
    <div class="content2">
      <h3 class="content-h3">关于我们</h3>
      <div class="content2-box">
        <div class="content2-box-text">
          <img src="@/assets/images/kingLogo.png" />
          <img src="@/assets/images/kingName.png" />
          <p>
            壹蝉科技成立于2021年，我们是一家科技创新服务型的科技公司，公司秉承以服务为根，改革创新为本的运营方针，重金打造国内领先的全品卡券发行平台，服务于互联网最前沿的数字消费及实体产品的消费需求，为合作伙伴提供互联互通的产品定制及综合系统服务解决方案，促进数字商品及实体商品的高效流转，助力企业降本增效及提升产品和品牌价值。
          </p>
        </div>
      </div>
    </div>
    <div class="content3">
      <h3 class="content-h3">壹蝉科技 未来服务探索</h3>
      <ul class="flexpub">
        <li v-for="(item, index) in exploreList" :key="index">
          <img :src="item.url" />
          <div>
            <p>{{ item.name1 }}</p>
            <p>{{ item.name2 }}</p>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { onMounted, reactive, toRefs, getCurrentInstance, onBeforeMount } from "vue";
export default {
  setup() {
    const { proxy } = getCurrentInstance();
    const data = reactive({
      exploreList: [
        {
          name1: "科技助农",
          name2:
            "壹蝉科技将打造助农板块，帮助农民完成农产品商品包装、销售、配送等一站式服务。产品涵盖农副产品、生鲜、果蔬等。",
          url: require("@/assets/images/exploreIcon1.png"),
        },
        {
          name1: "更多折扣商品",
          name2:
            "专业的运营团队，网罗各类折扣商品，持续为会员提供性价比更高的产品服务。",
          url: require("@/assets/images/exploreIcon2.png"),
        },
        {
          name1: "慈善捐赠",
          name2: "积小善 成大爱，把不需要的捐给有需要的人。",
          url: require("@/assets/images/exploreIcon3.png"),
        },
        {
          name1: "礼品商城",
          name2:
            "持续丰富商品产品内容，面向会员提供物美价廉的产品，减少企业采购流程及成本的支出。",
          url: require("@/assets/images/exploreIcon4.png"),
        },
      ],
    });

    // 页面加载时调用
    onMounted(() => {
    });
    return {
      ...toRefs(data),
    };
  },
};
</script>
<style lang='scss' scoped>
$primary-color: #2b2b2b;
$primary-size: 50px;
.about {
  .content1 {
    width: 100%;
    height: 773px;
    background: url(../assets/images/aboutBg.jpg) no-repeat;
    background-size: 100%,100%;
    margin-bottom: 100px;
    .content1-box {
      padding-left: 130px;
      padding-top: 180px;
      color: #ffffff;
      text-align: left;
      h3 {
        font-size: 50px;
        font-weight: bold;
        margin-bottom: 150px;
      }
      p {
        font-size: 30px;
        &:first-of-type {
          margin-bottom: 30px;
        }
      }
    }
  }
  .content2 {
    .content2-box {
      width: 100%;
      height: 829px;
      background: url(../assets/images/aboutBg2.png) no-repeat;
      background-size: 100%,100%;
      margin-top: 104px;
      .content2-box-text {
        width: 982px;
        margin-left: 724px;
        flex-direction: column;
        display: flex;
        justify-content: center;
        align-items: center;
        padding-top: 143px;
        img {
          &:first-of-type {
            width: 223px;
            height: 67px;
          }
          &:last-of-type {
            width: 217px;
            height: 54px;
            margin-top: 10px;
          }
        }
        p {
          font-size: 28px;
          color: $primary-color;
          text-align: left;
          margin-top: 70px;
          line-height: 54px;
          text-indent: 60px;
        }
      }
    }
  }
  .content3 {
    padding: 68px 0px 100px 0px;
    ul {
      width: 1316px;
      flex-wrap: wrap;
      margin-top: 83px;
      li {
        display: flex;
        margin-top: 30px;
        width: calc(calc(100% / 2) - 15px);
        &:nth-of-type(3) {
          img {
            border: 2px solid #e3cf9d;
            border-radius: 36px;
          }
        }
         &:nth-of-type(2), &:nth-of-type(4){
            p {
              &:first-of-type {
              
                 &::after{
              
               left: 0px;
              }
         }
            }}
        &:first-of-type,
        &:nth-of-type(3) {
          flex-direction: row-reverse;
          div {
            margin-right: 24px;
            p {
              &:first-of-type {
                text-align: right;
                 &::after{
              
               right: 0px;
              }
              }
              &:last-of-type {
                text-align: left;
              }
            }
          }
        }

        div {
          margin-left: 24px;
          p {
            color: $primary-color;
            text-align: left;
            &:first-of-type {
              font-size: 30px;
              position: relative;
              &::after{
                content: '';
                width: 91px;
                height: 2px;
                background:#D9D9D9 ;
                position: absolute;
                bottom: -5px;
              
              }
            }
            &:last-of-type {
              font-size: 20px;
              font-weight: 300;
               margin-top: 23px;
            }
          }
        }
        img {
          width: 313px;
          height: 206px;
        }
      }
    }
  }
}
.content-h3 {
  color: $primary-color;
  font-size: $primary-size;
  position: relative;
  &::after {
    content: "";
    width: 237px;
    height: 6px;
    background: #808080;
    position: absolute;
    left: 50%;
    margin-left: -118.5px;
    bottom: -30px;
  }
}
.flexpub {
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
}
</style>